import axios from 'axios'

import config from '../config'

const getAll = () => {
  return axios.get(`${config.baseUrl}/expenses`, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('DonFelipeToken') }`,
    },
  })
}

const getById = id => {
  return axios.get(`${config.baseUrl}/expenses/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const getByMonth = monthSelected => {
  return axios.get(`${config.baseUrl}/expenses/month/${monthSelected}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const getByFilter = filters => {
  return axios.post(`${config.baseUrl}/expenses/filter/`, filters, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const createExpense = expense => {
  return axios.post(`${config.baseUrl}/expenses`, expense, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const updateExpense = (expenseId, updates) => {
  return axios.put(`${config.baseUrl}/expenses/${expenseId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const getUploadAuthorization = (trialId, docType) => {
  return axios.post(`${config.baseUrl}/expenses/getUploadAuthorization`, {
    trialId, docType
  }, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`,
    },
  })
}

const addDocumentMtDB = (doc) => {
  return axios.post(`${config.baseUrl}/expenses/addDocumentMtDB`, doc, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`,
    },
  })
}

const deleteExpense = (id, docId)=> {
  return axios.delete(`${config.baseUrl}/expenses/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`
    },
  });
};

const replicateExpense = (id, qtd)=> {
  console.log(qtd);
  return axios.post(`${config.baseUrl}/expenses/replicate/${id}`, qtd, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`
    },
  });
};

const attachDocument = (pdfPath, billPath) => {
  return axios.post(`${config.baseUrl}/expenses/attachDocument`, {
    urlOne: pdfPath,
    urlTwo: billPath
  }, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`,
    },
  });
};

const addTrialMtDB = (trial) => {
  return axios.post(`${config.baseUrl}/expenses/addTrialMtDB`, trial, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`,
    },
  })
}

const editTrialMtDB = (info) => {
  return axios.post(`${config.baseUrl}/expenses/move-document-trial`, info, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`,
    },
  })
}

const getTrialMonthByCustomer = (customerId, date) => {
  return axios.get(`${config.baseUrl}/expenses/${date}/customer/${customerId}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`,
    },
  })
}

export default { getAll, getById, createExpense, updateExpense, getByMonth, getByFilter, getUploadAuthorization, getTrialMonthByCustomer, addDocumentMtDB, addTrialMtDB, attachDocument, deleteExpense, replicateExpense, editTrialMtDB }

import { transform } from 'lodash';
import React, { useState, useEffect } from 'react';

import Utils from '../Utils/Utils';

import './DatePagination.scss';

const DatePagination = ({ onDateChange }) => {
  const [selectedMonth, setSelectedMonth] = useState(getDefaultMonth());
  const [selectedMonthFormatted, setSelectedMonthFormatted] = useState(getDefaultMonthFormatted());
  const [showDate, setShowDate] = useState('none');
  const [showDateName, setShowDateName] = useState('block');

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);

    var date = new Date(Utils.returnDateYYYYMM(event.target.value));
    const formPt = date.toLocaleString('pt-br',{month:'long', year:'numeric'});
    
    setSelectedMonthFormatted(formPt.split(" ")[0]+" "+formPt.split(" ")[2]);
  };

  const handleDisplayChange = (event) => {
    if  (showDateName == 'block') {
      setShowDateName('none');
      setShowDate('block');
    }else{
      setShowDateName('block');
      setShowDate('none');
    }
  };
  

  const increaseMonth = () => {
    const currentMonth = new Date(`${Utils.returnDateYYYYMM(selectedMonth)}-01T12:00:00`);
    currentMonth.setMonth(currentMonth.getMonth() + 1);
    if (currentMonth.getMonth() === 12) {
      currentMonth.setMonth(0); // Set it to January
      currentMonth.setFullYear(currentMonth.getFullYear() + 1); // Increase the year
    }
    const formattedDate = formatMonth(currentMonth);
    setSelectedMonth(formattedDate);
  };

  const decreaseMonth = () => {
    const currentMonth = new Date(`${selectedMonth}-01T12:00:00`);
    currentMonth.setMonth(currentMonth.getMonth() - 1);
    if (currentMonth.getMonth() === -1) {
      currentMonth.setMonth(11); // Set it to December
      currentMonth.setFullYear(currentMonth.getFullYear() - 1); // Decrease the year
    }
    const formattedDate = formatMonth(currentMonth);
    setSelectedMonth(formattedDate);
  };

  function getDefaultMonth() {
    const now = new Date();
    now.setDate(1); // Set the day to 1st of the month
    now.setHours(12, 0, 0, 0); // Set the time to 12:00:00
    const yearMonth = `${now.getFullYear()}-${String(now.getMonth()+1).padStart(2, '0')}`;
    return yearMonth;
  }

  function getDefaultMonthFormatted() {
    const now = new Date(`${selectedMonth}-01T12:00:00`);
    const yearMonth = `${now.getFullYear()}-${String(now.getMonth()+1).padStart(2, '0')}`;
    const formPt = now.toLocaleString('pt-br',{month:'long', year:'numeric'});

    return formPt.split(" ")[0]+" "+formPt.split(" ")[2];
  }

  const formatMonth = (date) => {
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}`;

    var date = new Date(date);
    const formPt = date.toLocaleString('pt-br',{month:'long', year:'numeric'});
    setSelectedMonthFormatted(formPt.split(" ")[0]+" "+formPt.split(" ")[2]);

    return formattedDate;
  };

  useEffect(() => {
    onDateChange(selectedMonth);
  }, [selectedMonth, onDateChange]);

  return (
    <div className='wrapper-date-filter'>
      <div className='pagination' style={{ marginTop: '20px' }}>
        <button onClick={decreaseMonth}>&lt;</button>
        <input
          type="month"
          value={selectedMonth}
          lang="pt"
          className='inputDate'
          style={{ width: '130px', display: showDate }}
          onChange={handleMonthChange}
        />
        <b id='smf' className='plm' style={{display: showDateName, cursor:'pointer'}}>{selectedMonthFormatted}</b>
        <button onClick={increaseMonth}>&gt;</button>
      </div>
    </div>
  );
};

export default DatePagination;

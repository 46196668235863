import { useEffect, useRef } from "react";
import { PDFDocument } from "pdf-lib";

const usePrevious = (value) => {
    const ref = useRef();
    
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    
    return ref.current; //in the end, return the current ref value.
}

const currencyFormat = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}


const BLACKLIST = ['00000000000000','11111111111111','22222222222222','33333333333333','44444444444444','55555555555555','66666666666666','77777777777777','88888888888888','99999999999999','12345678909876'];

const isValidCNPJ = (cnpj) => {
    // Remove non-digits
    const cleanCNPJ = cnpj.replace(/[^\d]/g, '');

    // Check against the blacklist
    if (BLACKLIST.includes(cleanCNPJ)) {
        return false; // CNPJ is in the blacklist
    }

    // Your other CNPJ validation logic here
    // ...

    return /^\d{14}$/.test(cleanCNPJ); // Basic length check
};

const isValidDate = (date) => {
    const nd = new Date(date);
    if(nd instanceof Date && !isNaN(nd.valueOf())){
        return true;
    }

    return false;
};

const returnDateYYYYMM = (date) => {
    const YYYYObj = date.toString().split("-");

    if  (YYYYObj.length == 2 && isNumber(YYYYObj[0]) && isNumber(YYYYObj[1])){
        if (YYYYObj[0] > 1500 && YYYYObj[0] <= 9999 && YYYYObj[1] > 0 && YYYYObj[1] < 13 ){
            return date;
        }
    }

    const fullDate = new Date();
    fullDate.setDate(1); // Set the day to 1st of the month
    fullDate.setHours(12, 0, 0, 0); // Set the time to 12:00:00
    const yearMonth = `${fullDate.getFullYear()}-${String(fullDate.getMonth()+1).padStart(2, '0')}`;
    return yearMonth;
}

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const convertImageToPdf = async (file) => {
    let imgBytes;
    let imgType = file.type;
  
    // Se não for JPG/PNG, converte para PNG primeiro
    if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
      file = await convertToPng(file);
      imgType = "image/png"; // Atualiza o tipo após conversão
    }
  
    imgBytes = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.create();
  
    let img;
    if (imgType === "image/png") {
      img = await pdfDoc.embedPng(imgBytes);
    } else {
      img = await pdfDoc.embedJpg(imgBytes);
    }
  
    const page = pdfDoc.addPage([img.width, img.height]);
    page.drawImage(img, { x: 0, y: 0, width: img.width, height: img.height });
  
    return await pdfDoc.save();
  };
  
  // Função para converter imagens não suportadas para PNG usando Canvas
  const convertToPng = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        canvas.toBlob((blob) => {
          resolve(new File([blob], file.name.replace(/\.[^.]+$/, ".png"), { type: "image/png" }));
        }, "image/png");
      };
      img.onerror = (error) => reject(error);
    });
  };

export default { usePrevious, currencyFormat, isValidCNPJ, isValidDate, returnDateYYYYMM, convertImageToPdf};
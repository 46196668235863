import React, { useRef, useState } from 'react';
import './FileInput.scss'; // Import your CSS file for styling

const FileInput = ({ onChange }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('-');

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setFileName(files[0].name);
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="custom-file-input">
      <div className="fake-input" onClick={handleClick}>
        <span>{fileName}</span>
        <button type="button">Selecionar</button>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        // accept="image/jpeg,image/jpg,image/png,application/pdf,.re,.pfx,.xml,.xls,.xlsx,.xlsm,.doc,.docx,.docm"
        accept="image/*, application/pdf"
        className="hidden-input"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileInput;

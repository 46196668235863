import React, { useEffect, useState, useCallback } from 'react';

import Select from 'react-select';
import PrimaryButton from '../Layout/PrimaryButton';

import Utils from '../Utils/Utils';
import expensesService from '../../services/expenses';
 
const FilterBar = ({ companies, constructions, suppliers, appropriations, onFilterExpenses, selectedDate, requestComp, typeSearchP, orderByT }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedConstructions, setSelectedConstructions] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedAppropriations, setSelectedAppropriations] = useState([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
  const [selectedSituations, setSelectedSituations] = useState([]);
  const [selectedByDate, setSelectedByDate] = useState('dueDate');

  const [typeSearch, setTypeSearch] = useState(false);

  let ignoreState = false;
  
  const prevStartDate = Utils.usePrevious(startDate);
  const prevEndDate = Utils.usePrevious(endDate);
  const prevRequestComp = Utils.usePrevious(requestComp);

  const optPaymentApproach = [
    { value: 'Dinheiro', label: 'Dinheiro' },
    { value: 'Cheque', label: 'Cheque' },
    { value: 'Internet', label: 'Internet' },
    { value: 'Débito C.C.', label: 'Débito C.C.' }
  ];
  const permission = parseInt(sessionStorage.getItem('DonFelipePermission'), 10);
  const initialFields = {
    companiesState: [],
    constructionsState: [],
    suppliersState: [],
    appropriationsState: [],
    situationState: [],
    payment_aproach: permission === 4 ? optPaymentApproach.filter(column => {
      return !['Dinheiro'].includes(column.value);
    }) : optPaymentApproach
  };
  const [fields, setFields] = useState({ ...initialFields });

  const getLastDayOfMonth = (dateString) => {
    const firstDayOfMonth = new Date(`${dateString.substring(0, 7)}-01T12:00:00`);
    const lastDayOfMonth = new Date(firstDayOfMonth);

    lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1, 0);
    lastDayOfMonth.setUTCHours(0, 0, 0, 0);

    return lastDayOfMonth.toISOString().split('T')[0];
  };

  useEffect(() => {
    if(!typeSearch) {
      if(requestComp !== prevRequestComp && JSON.stringify(selectedDate) !== '{}' ) {
        setStartDate(`${selectedDate}-01`);
        setEndDate(getLastDayOfMonth(`${selectedDate}-01`));
        ignoreState = true;
        handleFilterExpenses();
      } else if(JSON.stringify(selectedDate) !== '{}') {
        setStartDate(`${selectedDate}-01`);
        setEndDate(getLastDayOfMonth(`${selectedDate}-01`));
  
        setSelectedCompanies([]);
        setSelectedConstructions([]);
        setSelectedSuppliers([]);
        setSelectedAppropriations([]);
        setSelectedPaymentMethods([]);
        setSelectedSituations([]);
        ignoreState = false;
  
        handleFilterExpenses();
      }
    }
  }, [requestComp, selectedDate, startDate, endDate, typeSearch, orderByT]);

  useEffect(() => {
    setTypeSearch(false)
  }, [typeSearchP]);

  const convertedArray = useCallback((originalObject, fieldName) => {
    return originalObject.map((field) => ({
      value: field[`${fieldName}ID`],
      label: field.name,
    }));
  }, []);

  const handleSubmit = () => {
    ignoreState = true;
    setTimeout(() => {
      handleFilterExpenses();
      // setTypeSearch(false);
    }, 100);
  }

  const fetchCompanies = useCallback(async () => {
    try {
      const companiesArray = await companies;
      if (companiesArray.length) {
        setFields((prevFields) => ({
          ...prevFields,
          companiesState: convertedArray(companiesArray, 'company'),
        }));
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }, [companies, convertedArray]);

  useEffect(() => {
    if (!fields.companiesState.length) {
      fetchCompanies();
    }
  }, [fields.companiesState.length, fetchCompanies, convertedArray]);

  const handleFilterExpenses = async () => {
    try {
      if((JSON.stringify(startDate) !== '{}' && startDate.length && startDate !== prevStartDate) || ignoreState) {
        const userID = parseInt(sessionStorage.getItem('DonFelipeUserId'), 10);
        const permissionLevel = parseInt(sessionStorage.getItem('DonFelipePermission'), 10);
        
        const expensesFilter = await expensesService.getByFilter({
          startDate,
          endDate,
          selectedCompanies,
          selectedConstructions,
          selectedSuppliers,
          selectedAppropriations,
          selectedPaymentMethods,
          selectedSituations,
          selectedByDate,
          orderByT,
          userID,
          permissionLevel, 
        });
        onFilterExpenses(expensesFilter.data);
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  return (
    <div className='wrapper-content'>
      <div className='form-box'>
        <form className="expenses-screen_ _form" action="#">
          <div className='wrapper-columns search-bar'>
            <div className='column'>
              <label>
                <span>Data Inicial</span>
                <input type="date" value={startDate} onChange={(e) => {
                      setTypeSearch(true);
                      setStartDate(e.target.value)
                    }
                  } />
              </label>

              <label>
                <span>Data Final</span>
                <input type="date" value={endDate} onChange={(e) => {
                      setTypeSearch(true);
                      setEndDate(e.target.value)
                    }} />
              </label>
            </div>
            <div className='column'>
              <label>
                <span>Empresa</span>
                <Select
                  isMulti
                  options={fields.companiesState}
                  value={selectedCompanies}
                  onChange={(selected) => setSelectedCompanies(selected)}
                  placeholder="Selecione"
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '11px'
                    })}}
                />
              </label>

              <label>
                <span>Obra</span>
                <Select
                  isMulti
                  options={convertedArray(constructions, 'construction')}
                  value={selectedConstructions}
                  onChange={(selected) => setSelectedConstructions(selected)}
                  placeholder="Selecione"
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '11px'
                    })}}
                />
              </label>
            </div>
            <div className='column'>
              <label>
                <span>Fornecedor</span>
                <Select
                  isMulti
                  options={convertedArray(suppliers, 'supplier')}
                  value={selectedSuppliers}
                  onChange={(selected) => setSelectedSuppliers(selected)}
                  placeholder="Selecione"
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '11px'
                    })}}
                />
              </label>

              <label>
                <span>F. Pgto</span>
                <Select
                  isMulti
                  options={fields.payment_aproach}
                  value={selectedPaymentMethods}
                  onChange={(selected) => setSelectedPaymentMethods(selected)}
                  placeholder="Selecione"
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '11px'
                    })}}
                />
              </label>
            </div>
            <div className='column'>
              <label>
                <span>Situação</span>
                <Select
                  isMulti
                  options={[{ value: '1', label: 'Pago' }, { value: '0', label: 'À Pagar' }, { value: '3', label: 'Conciliado' }, { value: '2', label: 'Não Conciliado' }]}
                  value={selectedSituations}
                  onChange={(selected) => {
                    setSelectedSituations(selected)
                  }}
                  placeholder="Selecione"
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '11px'
                    })}}
                />
              </label>
              <label>
                <span>Apropriação</span>
                <Select
                  isMulti
                  options={convertedArray(appropriations, 'appropriation')}
                  value={selectedAppropriations}
                  onChange={(selected) => setSelectedAppropriations(selected)}
                  placeholder="Selecione"
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '11px'
                    })}}
                />
              </label>
            </div>
            <div className='column'>
              <div style={{height: '75px'}}></div>
              <PrimaryButton fullWidth onClick={handleSubmit}>Procurar</PrimaryButton>
            </div>
          </div>
          <div className='wrapper-labels'>
            <label style={{textAlign: 'left', marginTop: 15}}>Filtrar por:</label>
            <label className='label-radio'>
              <input type="radio" name="option" value="vencimento" 
                checked={selectedByDate === 'dueDate'}
                onClick={() => setSelectedByDate('dueDate')} />
              &nbsp;Vencimento
            </label>
            <label className='label-radio'>
              <input type="radio" name="option" value="pagamento" 
                checked={selectedByDate === 'paymentDate'}
                onClick={() => setSelectedByDate('paymentDate')} />
              &nbsp;Pagamento
            </label>
            <label className='label-radio'>
              <input type="radio" name="option" value="competencia-contabil" 
                checked={selectedByDate === 'expenseDate'}
                onClick={() => setSelectedByDate('expenseDate')} />
              &nbsp;Competência Contábil
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterBar;

import React, { useState } from "react";
import PrimaryButton from '../../components/Layout/PrimaryButton';
import FileInput from '../../components/Layout/FileInput';
import loaderIcon from '../../assets/loader.svg';
import './ModalAttach.scss';

import expensesService from '../../services/expenses';
import * as azureStorage from "@hobbytraceorg/azure-storage-browser";
import Utils from '../../components/Utils/Utils';

function ModalAttach({ onCloseModal, url, mtId, dueDate, companies, companyID, onUploadSuccess, expenseID }) {
  const initialFields = { 
    pdfPath: { value: 0, valid: true, validationMessage: '' }
  };
  const [fields, setFields] = useState({ ...initialFields });
  const [successForm, setSuccessForm] = useState(false);
  const [loading, setLoader] = useState(false);

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    const name = String(file.name).split(".").slice(0, -1).join(".");
    const date = new Date();
  
    setFields((prevFields) => ({
      ...prevFields,  
      [fieldName]: {
        ...prevFields[fieldName],
        value: {
          file: file,
          filename: file.name,
          name,
          displayDate: date,
          date: date.toISOString(),
        }
      }
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {};
    const filesUpload = [];
    if (fields.pdfPath.value !== 0) {
      filesUpload.push({
        type: 'pdfPath',
        objFile: fields.pdfPath.value
      });
    }

    // const uploadInfoArray = [];
    setLoader(true);
    if(url.indexOf('https') < 0) {
      // Single Upload
      const trialIdByCustomer =  await expensesService.getTrialMonthByCustomer(mtId, dueDate.substring(0, 7));
      if(!trialIdByCustomer.data.length) {
        // Create Trial
        const trialData = {
          "customerId": mtId,
          "name": dueDate.substring(0, 7),
          "status": "private",
          "description": `Processo criado na empresa - ${companies.find(item => item.companyID === companyID).name}`,
          "users": [330],
          "historic": "{0} criou o processo."
        };
        expensesService.addTrialMtDB(trialData)
        .then(async (response) => {
          const trialIdByCustomer = response.data.id
          const uploadInfo = await uploadFile({ trialId: trialIdByCustomer, file: filesUpload[0].objFile.file, merge: filesUpload.length });
          setLoader(false);
          setSuccessForm(true);
          onUploadSuccess(uploadInfo.url, expenseID);
        });
      } else {
        expensesService.getTrialMonthByCustomer(mtId, dueDate.substring(0, 7)).then(async (response) => {
          const uploadInfo = await uploadFile({ trialId: response.data[0].id, file: filesUpload[0].objFile.file, merge: filesUpload.length });
          setLoader(false);
          setSuccessForm(true);
          onUploadSuccess(uploadInfo.url, expenseID);
        });
      }
    } else {
      const uploadPromises = filesUpload.map(async (fileItem, index) => {
        // Merge Files
        const uploadInfos = await uploadFile({ trialId: url.match(/\/(\d+)\/[^/]+\.pdf$/)?.[1], file: fileItem.objFile.file, merge: filesUpload.length });  
        formData['pdfPath'] = uploadInfos.url;
        // uploadInfoArray.push(uploadInfos);
      });
      await Promise.all(uploadPromises);
      const attachDocument = await expensesService.attachDocument(url, formData['pdfPath']);
      setLoader(false);
      setSuccessForm(attachDocument.data.data);
    }
  };

  const uploadFile = async (doc) => {
    const lastDotIndex =  String(doc.file.name).lastIndexOf('.');
    const docType = String(doc.file.name).substring(lastDotIndex + 1).toLowerCase();
    let contentType = "application/pdf";
    let fileToUpload = doc.file;

    // Se for uma imagem, converte para PDF antes de fazer o upload
    if (["jpg", "jpeg", "png"].includes(docType)) {
      try {
        const pdfBytes = await Utils.convertImageToPdf(doc.file);
        fileToUpload = new File([pdfBytes], doc.file.name.replace(/\.[^.]+$/, ".pdf"), {
          type: "application/pdf",
        });
      } catch (error) {
        console.error("Erro ao converter imagem para PDF:", error);
        return Promise.reject(error); 
      }
    }

    return new Promise((resolve, reject) => {
      const azureToken = sessionStorage.getItem('DonFelipeAToken');

      expensesService.getUploadAuthorization(doc.trialId, "pdf") //Agora sempre seré pdf
      .then((response) => {
        let authorization = response.data;
        
        const storageService = azureStorage.createBlobServiceWithSas(
          authorization.host,
          azureToken
        );
        storageService.createContainerIfNotExists(
            authorization.share,
          (err) => {
            if (err) return reject(err);
            storageService.createBlockBlobFromBrowserFile(
              authorization.share,
              `${authorization.directory}/${authorization.filename}`,
              fileToUpload,
              { contentSettings: { contentType } },
              (err) => {
                if (err) return reject(err);
                resolve(authorization);
              }
            );
          }
        );
      })
      .catch((error) => {
        console.error('Error getting authorization:', error);
      });
    });
  }
  
  return (
    <div className='modal-attach'>
      <div className="inner-content" style={{maxWidth: '700px'}}>
        <>
          <h1>{ !url.indexOf('https') < 0 ? 'Vincular PDF' : 'Anexar arquivo' }</h1>
           <button className="btn-close" style={{border: '2px solid #fff', top: '-10px', right: '-10px' }} onClick={() => onCloseModal(false)}>X</button>
          {
            !successForm && !loading ? (
              <div className="wrapper-form">
                <label>
                  <FileInput tabIndex={1} defaultValue={fields.pdfPath.value} name="pdfPath" onChange={(e) => handleFileChange(e, 'pdfPath')} />
                </label>
                <PrimaryButton fullWidth onClick={handleSubmit} tabIndex={11}>Salvar</PrimaryButton>
              </div>
            ) : loading ? (
              <img className="loader" width={100} height={100} src={loaderIcon} alt="Carregando..." />
            ) : (
              <div className="wrapper-form">
                <h2>
                  { !url.indexOf('https') < 0 ? 'Arquivo vinculado com sucesso!' : 'Arquivo anexado com sucesso!' }  
                </h2>
              </div>
            )
          }
        </>
      </div>
    </div>
  );
}

export default ModalAttach;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import reportsService from '../../../services/reports';
import loaderIcon from '../../../assets/loader.svg';
import debounce from 'lodash/debounce';
import constructionsService from '../../../services/constructions';

import MaskInput from '../../../components/Layout/MaskInput';
import ScrollableTable from '../../../components/DataGrid/DataGrid';
import Utils from '../../../components/Utils/Utils';

import Select from 'react-select';

function AnnualAppropriationView() {
  const [selectedYear, setSelectedYear] = useState({value:new Date().getFullYear(), label:new Date().getFullYear()});
  const [annualAppropriationReport, setAnnualAppropriationReport] = useState(null);
  const [constructions, setConstructions] = useState([]);
  const [selectedConstruction, setSelectedConstruction] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalYearlyForecast, setTotalYearlyForecast] = useState(0); 
  const selectedYearRef = useRef(selectedYear);

  useEffect(() => {
    const fetchAnnualAppropriationReport = async () => {
      try {
        setLoader(true);
        const result = await reportsService.getAnnualAppropriationReport(selectedYear["value"].toString(), {selectedConstruction});
        setLoader(false);
        if (Array.isArray(result.data)) {
          const cleanedData = result.data.map(item => ({
            ...item,
            month: Object.fromEntries(
              Object.entries(item.month).filter(([key, value]) => key !== '' && key !== null)
            )
          }));
          setAnnualAppropriationReport(cleanedData);
        } else {
          console.error('Invalid data structure for annualAppropriationReport:', result);
        }
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    const getConstructions = async () => {
      try {
        const construction = await constructionsService.getAll();
        setConstructions(construction.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    selectedYearRef.current = selectedYear;

    getConstructions();
    fetchAnnualAppropriationReport();
  }, [selectedYear, selectedConstruction]);

  useEffect(() => {
    if (annualAppropriationReport) {
      let totalForecast = annualAppropriationReport.reduce((total, item) => {
        return total + (item.yearlyExpense ? parseFloat(item.yearlyExpense) : 0);
      }, 0);
      setTotalYearlyForecast(totalForecast);
    }
  }, [annualAppropriationReport]);
  
  const convertedArray = useCallback((originalObject, fieldName) => {
    return originalObject.map((field) => ({
      value: field[`${fieldName}ID`],
      label: field.name,
    }));
  }, []);

  const lastFiveYears = Array.from({ length: 5 }, (_, i) => {
    const year = (new Date().getFullYear() - i).toString();
    return { value: year, label: year };
  });

  // Function to handle input change
  const handleInputChange = (e, constructionID, name) => {
    const numericAmount = e.formattedValue.length ? e.formattedValue.replace(/[^\d,]/g, '').replace(',', '.') : '0';
  
    // Update the local annualAppropriationReport state for immediate UI feedback
    const updatedReport = annualAppropriationReport.map(item => {
      if (item.constructionID === constructionID) {
        return {
          ...item,
          yearlyExpense: parseFloat(numericAmount)
        };
      }
      return item;
    });
  
    setAnnualAppropriationReport(updatedReport); // Update local state first for UI feedback
  
    // Debounced update to backend
    debouncedUpdateForecast(constructionID, numericAmount);
  };

  // Function to update the forecast with debounce
  const debouncedUpdateForecast = useCallback(
    debounce((ConstructionID, value) => {
      updateForecast(ConstructionID, value);
    }, 1500),
    []
  );

  // Function to update the forecast
  const updateForecast = async (ConstructionID, value) => {
    try {
      await reportsService.updateYearForecast(ConstructionID, {
        monthlyExpense: parseFloat(value),
        forecastYear: `${selectedYearRef.current["value"].toString()}`
      });
    } catch (error) {
      console.error('Error updating forecast:', error);
    }
  };

  // Render loading or error state while waiting for data
  if (annualAppropriationReport === null) {
    return <img className="loader" src={loaderIcon} alt="Carregando..." />;
  }

  if (annualAppropriationReport instanceof Error) {
    return <div>Error fetching data: {annualAppropriationReport.message}</div>;
  }

  // Extract unique months from the data
  const uniqueMonths = Array.from(
    new Set(
      annualAppropriationReport.reduce((months, item) => [...months, ...Object.keys(item.month)], [])
    )
  );

  const setSelectedYear1 = (s) => {
    console.log(s);
    setSelectedYear(s);
    console.log("setado:");
    console.log(selectedYear);
  };

  const monthsInPortuguese = [
    "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
    "Jul", "Ago", "Set", "Out", "Nov", "Dez"
  ];

  // Define columns dynamically
  const dynamicColumns = [
    {
      Header: 'Obra',
      accessor: 'constructionName',
      width: 90,
      textAlign: 'left',
    },
    {
      Header: 'Apropriação',
      accessor: 'appropriationName',
      width: 90,
      textAlign: 'left',
    },
    {
      Header: `Previsão`,
      accessor: 'yearlyExpenseInput',
      width: 70,
      textAlign: 'left',
    },

    // Dynamically generate columns for each unique month
    ...uniqueMonths
    // .filter(month => month && month.includes('-'))
    .map(month => ({
      Header: `${monthsInPortuguese[(parseInt(month.split('-')[1], 10) - 1)]}/${month.split('-')[0]}` ,
      accessor: `month.${month}`, // Use the accessor to access nested properties
      width: 50, // Adjust the width as needed
      textAlign: 'left',
    })),
    {
      Header: 'Total Ano',
      accessor: 'totalYear',
      width: 80,
      textAlign: 'left',
    },
  ];

  let totalConstructionYear = 0;

  const formattedData = annualAppropriationReport.map((item) => {
    const formattedItem = {
      constructionName: item.constructionName,
    };

    // Handling the yearly expense input
    item['yearlyExpenseInput'] = (
      <MaskInput
        type="amount"
        defaultValue={Utils.currencyFormat(item.yearlyExpense ? item.yearlyExpense : 0)}
        placeholder={"R$ 0,00"}
        onValueChange={(e) => handleInputChange(e, item.constructionID, 'yearlyExpense')}
      />
    );

    let totalYear = 0;
    Object.entries(item.month).forEach(([month, value]) => {
      const cleanedValue = String(value).replace('R$', '').replace(/\./g, '').replace(',', '.').trim();
      const valueFix = cleanedValue === '-' ? 0 : parseFloat(cleanedValue);

      if (isNaN(valueFix)) {
        console.error(`Invalid value for month ${month}: ${value}`);
      }
      
      formattedItem[`month.${month}`] = isNaN(valueFix) ? 0 : valueFix;
      totalYear += isNaN(valueFix) ? 0 : valueFix;
    });
    totalConstructionYear += totalYear;
    item.totalYear = totalYear ? totalYear.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }) : '-';
    return formattedItem;
  });

  for (const [key, value] of Object.entries(annualAppropriationReport)) {
    for (const [keyM, valueMonth] of Object.entries(value.month)) {
      if (valueMonth === 0) {
        annualAppropriationReport[key].month[keyM] = "-";
      } else {
        annualAppropriationReport[key].month[keyM] = valueMonth.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      }
    }
  }

  const tableFooter = (
    <>
      <tr className='tfoot'>
        <td width={360}></td>
        <td width={360}><strong>Total:</strong></td>
        <td><strong>{totalYearlyForecast.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></td>
        {uniqueMonths.map(month => (
          <td key={month} width={80}>
            <strong>
              {
                Object.values(formattedData.reduce((totals, item) => {
                const value = item[`month.${month}`] === '-' ? 0 : item[`month.${month}`];
                totals[month] = (totals[month] || 0) + value;
                return totals;
              }, {})).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </strong>
          </td>
        ))}
        <td><strong>{totalConstructionYear.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></td>
      </tr>
    </>
  );

  // Render the data
  return (
    <>

      <div className='wrapper-content'>
        <div className='form-box' style={{padding: '20px'}}>
          <form className="expenses-screen_ _form" action="#">
            <div className='wrapper-columns search-bar'>
              <div className='column'>
                  <label>
                    <span>Obras</span>
                    <Select
                      isMulti
                      options={convertedArray(constructions, 'construction')}
                      value={selectedConstruction}
                      onChange={(selected) => setSelectedConstruction(selected)}
                      placeholder="Selecione"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '11px'
                        })}}
                    />
                  </label>

              </div>
              <div className='column'>

                  <label>
                    <span>Ano</span>
                      <Select
                        options={lastFiveYears}
                        value={selectedYear}
                        onChange={(selected) => setSelectedYear1(selected)}
                        placeholder="2025"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '11px'
                          })}}
                      />
                  </label>

              </div>
            </div>
          </form>
        </div>
      </div>
      <br/>
      <div className='wrapper- report' style={{padding: '0 20px'}}>
        {
          loader ? (
            <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
          ) : (
            <ScrollableTable headers={dynamicColumns} height={'84vh'} dataRows={annualAppropriationReport} footer={tableFooter}  />
          )
        }
      </div>    
    </>
  );
}

export default AnnualAppropriationView;

import React, { useEffect, useState, useCallback } from 'react';
import './Expenses.scss';
import MaskInput from '../../components/Layout/MaskInput';
import PrimaryButton from '../../components/Layout/PrimaryButton';
import FileInput from '../../components/Layout/FileInput';
import loaderIcon from '../../assets/loader.svg';
import expensesService from '../../services/expenses';
import suppliersService from '../../services/suppliers';
import companiesService from '../../services/companies';
import constructionsService from '../../services/constructions';
import appropriationsService from '../../services/appropriation';
import Select from 'react-select';
import Utils from '../../components/Utils/Utils';

import * as azureStorage from "@hobbytraceorg/azure-storage-browser";

const Expenses = () => {
  const dateNow = new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }).split(',')[0].split('/');
  const initialFields = { 
    expenseDate: { value: `${dateNow[2]}-${dateNow[0].padStart(2, '0')}-${dateNow[1]}`, valid: true, validationMessage: '' },
    companyID: { value: 0, valid: null, validationMessage: '' },
    constructionID: { value: '', valid: null, validationMessage: '' },
    invoiceID: { value: '', valid: true, validationMessage: '' },
    supplierID: { value: 0, valid: null, validationMessage: '' },
    dueDate: { value: `${dateNow[2]}-${dateNow[0].padStart(2, '0')}-${dateNow[1]}`, valid: true, validationMessage: '' },
    amount: { value: '0', valid: null, validationMessage: '' },
    comments: { value: '', valid: true, validationMessage: '' },
    installment: { value: 1, valid: true, validationMessage: '' },
    userID: { value: sessionStorage.getItem('DonFelipeUserId'), valid: true, validationMessage: '' },
    retention: { value: 0, valid: true, validationMessage: '' },
    paymentDate: { value: 'null', valid: true, validationMessage: '' },
    paid: { value: 0, valid: true, validationMessage: '' },
    conciliation: { value: 0, valid: true, validationMessage: '' },
    appropriation: { value: '', valid: true, validationMessage: '' },
    pdfPath: { value: '', valid: true, validationMessage: '' },
    billPath: { value: '', valid: true, validationMessage: '' },
    accounting: { value: '', valid: true, validationMessage: '' }
  };

  const [fields, setFields] = useState({ ...initialFields });
  const [loader, setLoader] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [appropriation, setAppropriation] = useState([]);
  const [mtId, setMtId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const supplierData = await suppliersService.getAll();
        const companyData = await companiesService.getAll();
        const constructionData = await constructionsService.getAll();
        const appropriationData = await appropriationsService.getAll();
        setSuppliers(supplierData.data);
        setCompanies(companyData.data);
        setConstructions(constructionData.data);
        setAppropriation(appropriationData.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    const name = String(file.name).split(".").slice(0, -1).join(".");
    const date = new Date();
  
    setFields((prevFields) => ({
      ...prevFields,  
      [fieldName]: {
        ...prevFields[fieldName],
        value: {
          file: file,
          filename: file.name,
          name,
          displayDate: date,
          date: date.toISOString(),
        }
      }
    }));
  };

  

  const handleInputChange = (e, _param, validation) => {
    const arrayValidation = ['invoiceID','comments', 'appropriation'];
    if (e.type !== 'change') {
      const fieldName = _param; // Use _param for MaskInput changes
      const formattedValue = e.formattedValue;
      setFields((prevFields) => ({
        ...prevFields,
        [fieldName]: {
          ...prevFields[fieldName],
          value: formattedValue,
          valid: !arrayValidation.includes(fieldName) ? validation : arrayValidation.includes(fieldName)
        },
      }));
    } else {
      const value = e.target.value;
      const name = e.target.name;
      setFields((prevFields) => ({
        ...prevFields,
        [name]: {
          ...prevFields[name],
          value: value,
          valid: !arrayValidation.includes(name) ? validation : arrayValidation.includes(name)
        },
      }));
    }
  };

  const handleSelectChange = (e, fieldName) => {
    const selectedValue = fieldName === 'installment' ? e.target.value : e;
    const isValid = selectedValue !== 0;

    console.log(selectedValue);

    if(fieldName === 'constructionID') {
      setMtId(constructions.find(item => item.constructionID === selectedValue.value).mtId);

      setFields((prevFields) => ({
        ...prevFields,
        ['accounting']: {
          value: selectedValue.value,
          valid: true,
          validationMessage: ''
        },
      }));
    }

    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: {
        value: selectedValue,
        valid: isValid,
        validationMessage: isValid ? '' : 'Por favor selecione um valor.'
      },
    }));
  };

  const sumDueDate = (date, qty) => {
    const currentDate = new Date(date);
    const multipliedDate = new Date(currentDate);
    multipliedDate.setMonth(currentDate.getMonth() + qty);
    return multipliedDate.toISOString().split('T')[0];
  };

  const createInstallments = (inputDate) => {
    const updatedInstallmentDates = [];

    for (let i = 0; i < parseInt(fields.installment.value, 10); i++) {
      updatedInstallmentDates.push(sumDueDate(inputDate, i));
    }
    return updatedInstallmentDates;
  };

  const validateInput = (param, validation) => {
    setFields({
      ...fields,
      [param]: {
        ...fields[param],
        valid: validation,
      },
    });
  };

  const handleCommentsChange = (e) => {
    setFields({
      ...fields,
      ['comments']: {
        ...fields['comments'],
        value: e.target.value
      },
    });
  };

  const checkForm = useCallback(() => {
    const isFormValid = Object.values(fields).every((item) => item.valid === true);
    return isFormValid;
  }, [fields]);

  const updateValidation = () => {
    setFields(prevFields => {
      const updatedFields = { ...prevFields };
      for (const key in updatedFields) {
        if (updatedFields[key].valid === null) {
          updatedFields[key] = { ...updatedFields[key], valid: false };
        }
      }
      return updatedFields;
    });
  };

  const uploadFile = async (doc) => {
    const lastDotIndex =  String(doc.file.name).lastIndexOf('.');
    const docType = String(doc.file.name).substring(lastDotIndex + 1).toLowerCase();
    let contentType = "application/pdf";
    let fileToUpload = doc.file;

    // Se for uma imagem, converte para PDF antes de fazer o upload
    if (["jpg", "jpeg", "png"].includes(docType)) {
      try {
        const pdfBytes = await Utils.convertImageToPdf(doc.file);
        fileToUpload = new File([pdfBytes], doc.file.name.replace(/\.[^.]+$/, ".pdf"), {
          type: "application/pdf",
        });
      } catch (error) {
        console.error("Erro ao converter imagem para PDF:", error);
        return Promise.reject(error); 
      }
    }

    return new Promise((resolve, reject) => {
      const azureToken = sessionStorage.getItem('DonFelipeAToken');

      expensesService.getUploadAuthorization(doc.trialId, "pdf") //Agora sempre seré pdf
      .then((response) => {
        let authorization = response.data;
        
        const storageService = azureStorage.createBlobServiceWithSas(
          authorization.host,
          azureToken
        );

        storageService.createContainerIfNotExists(
            authorization.share,
          (err) => {
            if (err) return reject(err);
            storageService.createBlockBlobFromBrowserFile(
              authorization.share,
              `${authorization.directory}/${authorization.filename}`,
              fileToUpload,
              { contentSettings: { contentType } },
              (err) => {
                if (err) return reject(err);
                resolve(authorization);
              }
            );
          }
        );
      })
      .catch((error) => {
        console.error('Error getting authorization:', error);
      });
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(!checkForm()) {
      updateValidation();
      setLoader(false);
    } else {
      setLoader(true);
      try {
        const formData = {};
        const updatedInstallmentDates = createInstallments(fields.dueDate.value);
        Object.entries(fields).forEach(([fieldName, fieldData]) => {
          if(fieldName === 'amount') {
            const formattedValue = fieldData.value;
            const cleanedNumericValue = parseFloat(formattedValue
              .replace(/[^\d,]/g, '') // Remove all non-digit and non-comma characters
              .replace(',', '.') // Replace the comma with a dot for decimal parsing
            );
            const result = cleanedNumericValue / updatedInstallmentDates.length;
            formData[fieldName] = result.toFixed(2);
          } else {
            if(fieldName === 'supplierID' || fieldName === 'constructionID' || fieldName === 'companyID') {
              formData[fieldName] = fieldData.value.value;
            } else if (fieldName === 'appropriation') {
              // Only the label string should be included
              formData[fieldName] = fieldData.value.label;
            } else {
              formData[fieldName] = fieldData.value;
            }
          }
        });

        const filesUpload = [];
        if (typeof fields.pdfPath.value === 'object' && fields.pdfPath.value !== null) {
          filesUpload.push({
            type: 'pdfPath',
            objFile: fields.pdfPath.value
          });
        }

        if (typeof fields.billPath.value === 'object' && fields.billPath.value !== null) {
          filesUpload.push({
            type: 'billPath',
            objFile: fields.billPath.value
          });
        }

        const expenses = [];    
        const trialIdByCustomer =  await expensesService.getTrialMonthByCustomer(mtId, fields.dueDate.value.substring(0, 7));
        if(!trialIdByCustomer.data.length) {
          const trialData = {
            "customerId": mtId,
            "name": fields.dueDate.value.substring(0, 7),
            "status": "private",
            "description": `Processo criado na empresa - ${companies.find(item => item.companyID === fields.companyID.value.value)?.name}`,
            "users": [330],
            "historic": "{0} criou o processo."
          };
          expensesService.addTrialMtDB(trialData)
          .then(async (response) => {
            const trialIdByCustomer = response.data.id
            await processExpense(filesUpload, trialIdByCustomer, formData, updatedInstallmentDates, expenses);
            setLoader(false); 
          });
        } else {
          await processExpense(filesUpload, trialIdByCustomer.data[0].id, formData, updatedInstallmentDates, expenses);
          setLoader(false);
        }
      } catch (error) {
        setSuccessForm(false);
        setHasErrors(true);
        console.error('Error saving expenses:', error);
      }
    }
  };

  const processExpense = async (filesUpload, trialIdByCustomer, formData, updatedInstallmentDates, expenses) => {
    const uploadInfoArray = [];
    let expenseCreated = null;
  
    // Upload dos arquivos
    const uploadPromises = filesUpload.map(async (fileItem) => {
      const uploadInfos = await uploadFile({ trialId: trialIdByCustomer, file: fileItem.objFile.file, merge: filesUpload.length });
      
      // Atribui o URL correto para cada tipo de arquivo
      if(fileItem.type === 'pdfPath') {
        formData['pdfPath'] = uploadInfos.url;
      }
      if(fileItem.type === 'billPath') {
        formData['billPath'] = uploadInfos.url;
      }
  
      // Armazena o retorno do upload
      uploadInfoArray.push(uploadInfos);
    });
  
    await Promise.all(uploadPromises);
  
    // Cria as despesas para cada data de vencimento
    for (const value of updatedInstallmentDates) {
      formData['dueDate'] = value;
      try {
        expenseCreated = await expensesService.createExpense(formData);
        expenses.push({ success: true, data: expenseCreated });
      } catch (e) {
        expenses.push({ success: false, data: 'error' });
        console.log('error in createExpense:', e);
      }
    }
  
    // Faz o attachDocument se houver mais de um arquivo (merge)
    if (uploadInfoArray.length > 1) {
      await expensesService.attachDocument(formData['pdfPath'], formData['billPath']);
  
      // No caso de merge, envia o dataToMd apenas uma vez com o primeiro arquivo
      const mergedUpload = uploadInfoArray[0];
      const dataToMd = {
        code: mergedUpload.code, // O código do arquivo
        url: mergedUpload.url,   // A URL do arquivo retornado
        trialId: trialIdByCustomer,
        name: suppliers.find(item => item.supplierID === fields.supplierID.value.value)?.name,
        physicalStorageLocation: 'Pendente',
        date: fields.dueDate.value,
        observation: `Documento ${mergedUpload.code} adicionado pelo sistema Don Felipe.`,
      };
  
      try {
        const expense = await expensesService.addDocumentMtDB(dataToMd);
        expenses.push({ success: true, data: expense });
      } catch (e) {
        expenses.push({ success: false, data: 'error' });
        console.log('error in addDocumentMtDB:', e);
      }
  
    } else if (uploadInfoArray.length === 1) {
      // Se houver apenas um arquivo, envia o dataToMd normalmente
      const singleUpload = uploadInfoArray[0];
      const dataToMd = {
        code: singleUpload.code, // O código do arquivo
        url: singleUpload.url,   // A URL do arquivo retornado
        trialId: trialIdByCustomer,
        name: suppliers.find(item => item.supplierID === fields.supplierID.value.value)?.name,
        physicalStorageLocation: 'Pendente',
        date: fields.dueDate.value,
        observation: `Documento ${singleUpload.code} adicionado pelo sistema Don Felipe.`,
      };
  
      try {
        const expense = await expensesService.addDocumentMtDB(dataToMd);
        expenses.push({ success: true, data: expense });
      } catch (e) {
        expenses.push({ success: false, data: 'error' });
        console.log('error in addDocumentMtDB:', e);
      }
    }
  
    setLoader(false);
  
    // Verifica se houve algum erro
    const hasErrors = expenses.some((expense) => !expense.success);
    if (hasErrors) {
      setSuccessForm(false);
      setHasErrors(true);
    } else {
      setSuccessForm(true);
      setHasErrors(false);
    }
  };  
  
  // const processExpense = async (filesUpload, trialIdByCustomer, formData, updatedInstallmentDates, expenses) => {
  //   const uploadInfoArray = [];
  //   let expenseCreated = null;
  //   const uploadPromises = filesUpload.map(async (fileItem, index) => {
  //     const uploadInfos = await uploadFile({ trialId: trialIdByCustomer, file: fileItem.objFile.file, merge: filesUpload.length }); 
  //     if(fileItem.type === 'pdfPath') {
  //       formData['pdfPath'] = uploadInfos.url;
  //     }

  //     if(fileItem.type === 'billPath') {
  //       formData['billPath'] = uploadInfos.url;
  //     }
  //     uploadInfoArray.push(uploadInfos);
  //   });
  //   await Promise.all(uploadPromises);
  //   for (const value of updatedInstallmentDates) {
  //     formData['dueDate'] = value;
  //     try {
  //       expenseCreated = await expensesService.createExpense(formData);
  //       expenses.push({ success: true, data: expenseCreated });
  //     } catch (e) {
  //       expenses.push({ success: false, data: 'error' });
  //       console.log('error in createExpense:', e);
  //     }
  //   }

  //   if(uploadInfoArray.length > 1) {
  //     await expensesService.attachDocument(formData['pdfPath'], formData['billPath']);
  //     setLoader(false); 
  //     for (const itemUpload of uploadInfoArray) {
  //       const dataToMd = {
  //         code: itemUpload.code,
  //         url: itemUpload.url,
  //         trialId: trialIdByCustomer,
  //         name: suppliers.find(item => item.supplierID === fields.supplierID.value.value)?.name,
  //         physicalStorageLocation: 'Pendente',
  //         date: fields.dueDate.value,
  //         observation: `Documento ${itemUpload.code} adicionado pelo sistema Don Felipe.`,
  //       };
        
  //       try {
  //         const expense = await expensesService.addDocumentMtDB(dataToMd);
  //         expenses.push({ success: true, data: expense });
  //       } catch (e) {
  //         expenses.push({ success: false, data: 'error' });
  //         console.log('error in addDocumentMtDB:', e);
  //       }
  //     }
  //     setLoader(false);
  //   }

  //   setLoader(false); 
  //   const hasErrors = expenses.some((expense) => !expense.success); 
  //   if (hasErrors) {
  //     setSuccessForm(false);
  //     setHasErrors(true);
  //   } else {
  //     setSuccessForm(true);
  //     setHasErrors(false);
  //   }
  // }

  const resetForm = () => {
    setFields({ ...initialFields });
    setLoader(false);
    setHasErrors(false);
    setSuccessForm(false);
  };

  const convertedArray = useCallback((originalObject, fieldName) => {
    return originalObject
      .filter((field) => field.isActive)
      .map((field) => ({
        value: field[`${fieldName}ID`],
        label: field.name,
      }));
  }, []);

  return (
    <div className='expenses-screen wrapper-content form-box'>
      <h2>Cadastro de Despesas</h2>
      {(hasErrors && !successForm) && (
        <>
          <div className="error-message" style={{ marginBottom: '20px' }}>
            Algo deu errado. Por favor, tente novamente.
          </div>
          <PrimaryButton onClick={resetForm}>Voltar</PrimaryButton>
        </>
      )}
      {(!hasErrors && successForm) && (
        <>
          <h2>
            Cadastro realizado no sistema!
          </h2>
          <PrimaryButton fullWidth onClick={resetForm}>Voltar</PrimaryButton>
        </>
      )}
      {loader && !hasErrors && !successForm ? (
        <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
      ) : (
        (!successForm && !loader && !hasErrors) && (
          <>
            <form className="expenses-screen__form" action="#">
              <div className='wrapper-columns'>
                <div className='column'>
                  <label>
                    Inserir Nota Fiscal
                    <FileInput tabIndex={1} defaultValue={fields.pdfPath.value} name="pdfPath" onChange={(e) => handleFileChange(e, 'pdfPath')} />
                  </label>
                </div>
                <div className='column'>
                  <label>
                    Inserir Boleto
                    <FileInput tabIndex={2} defaultValue={fields.billPath.value} name="billPath" onChange={(e) => handleFileChange(e, 'billPath')} />
                  </label>
                </div>
              </div>
              
              <div className='wrapper-columns'>
                <div className='column'>
                  <label>
                    Empresa<sup>*</sup>
                    <div className={`wrapper-select ${fields.companyID.valid === false ? 'has-error' : ''}`}>
                      <Select
                        options={convertedArray(companies, 'company')}
                        value={[fields.companyID.value]}
                        onChange={(e) => handleSelectChange(e, 'companyID')}
                        placeholder="Selecione"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '11px'
                          })}}
                      />
                      </div>
                  </label>

                  <label>
                    Obra/CC<sup>*</sup>
                    <div className={`wrapper-select ${fields.constructionID.valid === false ? 'has-error' : ''}`}>
                      <Select
                        options={convertedArray(constructions, 'construction')}
                        value={fields.constructionID.value}
                        onChange={(e) => handleSelectChange(e, 'constructionID')}
                        placeholder="Selecione"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '11px'
                          })}}
                      />
                    </div>
                  </label>

                  <label>
                    Nº da Nota Fiscal
                    <input
                      className={fields.invoiceID.valid === false ? 'has-error' : ''}
                      type="text"
                      name='invoiceID'
                      placeholder=""
                      onChange={(e) => handleInputChange(e, 'invoiceID', fields.invoiceID.value.length > 0)}
                      defaultValue={fields.invoiceID.value}
                      tabIndex={5}
                    />
                  </label>

                  <label>
                    Vencimento<sup>*</sup>
                    <input
                      className={fields.dueDate.valid === false ? 'has-error' : ''}
                      type="date"
                      name='dueDate'
                      defaultValue={fields.dueDate.value}
                      placeholder="Vencimento"
                      onChange={(e) => handleInputChange(e, 'dueDate', !isNaN(Date.parse(fields.dueDate.value)) )}
                      onBlur={() => validateInput('dueDate', !isNaN(Date.parse(fields.dueDate.value)))}
                      tabIndex={6}
                    />
                  </label>
                </div>
                <div className='column'>
                  <label>
                    Fornecedor<sup>*</sup>
                    <div className={`wrapper-select ${fields.supplierID.valid === false ? 'has-error' : ''}`}>
                      <Select
                        options={convertedArray(suppliers, 'supplier')}
                        value={fields.supplierID.value}
                        onChange={(e) => handleSelectChange(e, 'supplierID')}
                        placeholder="Selecione"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '11px'
                          })}}
                      />
                    </div>
                  </label>

                  <label>
                    Valor<sup>*</sup>
                    <MaskInput
                      type="amount"
                      className={fields.amount.valid === false ? 'has-error' : ''}
                      defaultValue={fields.amount.value}
                      placeholder="R$ 0,00"
                      onValueChange={(e) => handleInputChange(e, 'amount', fields.amount.value.length > 0 )}
                      onBlur={() => {
                        validateInput('amount', fields.amount.value.length > 0);
                      }}
                      tabIndex={8}
                    />
                  </label>

                  <label>
                    Forma de Pagamento<sup>*</sup>
                    <div className='wrapper-select'>
                      <select
                        tabIndex={9}
                        name="installment"
                        className={fields.installment.valid === false ? 'has-error' : ''}
                        onChange={(e) => handleSelectChange(e, 'installment')} 
                      >
                        <option>Selecionar</option>
                        <option value={1} selected>1 Parcela</option>
                        <option value={2}>2 Parcelas</option>
                        <option value={3}>3 Parcelas</option>
                        <option value={4}>4 Parcelas</option>
                        <option value={5}>5 Parcelas</option>
                        <option value={6}>6 Parcelas</option>
                        <option value={7}>7 Parcelas</option>
                        <option value={8}>8 Parcelas</option>
                        <option value={9}>9 Parcelas</option>
                        <option value={10}>10 Parcelas</option>
                      </select>
                    </div>
                  </label>

                  <label>
                    Apropriação
                    <div className='wrapper-select'>
                      <Select
                        options={convertedArray(appropriation, 'appropriation')}
                        value={[fields.appropriation.value]}
                        onChange={(e) => handleSelectChange(e, 'appropriation')}
                        placeholder="Selecione"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '11px'
                          })}}
                      />
                    </div>
                  </label>
                </div>
              </div>

              <textarea
                placeholder="Observação"
                name='comments'
                onChange={(e) => handleCommentsChange(e)}
                defaultValue={fields.comments.value}
                tabIndex={10}
              />
              <PrimaryButton fullWidth onClick={handleSubmit} tabIndex={11}>Salvar</PrimaryButton>
            </form>
          </>
        )
      )}
    </div>
  );
};

export default Expenses;
